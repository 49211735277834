
import { defineComponent } from "vue-demi";
import Channel from "@/components/owner/channel/index.vue";

export default defineComponent({
  name: "OwnerChannelView",
  components: {
    Channel,
  },
});
