
import { defineComponent } from "vue-demi";
import { CHANNEL_TABLE_OPTIONS } from "@/store/common/default/table";
import CommonTable from '@/components/common/table/index.vue';

export default defineComponent({
  name: 'OnwerChannel',
  data() {
    return {
      channels: CHANNEL_TABLE_OPTIONS()
    }
  },
  components: {
    CommonTable
  }
})
